import React from 'react';
import PropTypes from 'prop-types';

import { Box, Heading, Button } from 'grommet';
import { Close } from 'grommet-icons';

import { AppLayer } from '@/components/Control';


const ModalRenderer = ({
  toggle, isSmall, title, renderContent,
}) => {
  const radius = isSmall ? '0' : null;

  return (
    <AppLayer
      animate={false}
      responsive={false}
      full={isSmall}
      margin={isSmall ? 'none' : 'large'}
      round={radius}
      onClickOutside={toggle}
    >
      <Box
        flex={false}
        direction="row"
        justify="between"
        border={{ color: 'light-3', side: 'bottom', size: 'xsmall' }}
      >
        <Heading level={3} margin="medium">
          {title}
        </Heading>
        <Box justify="center" pad="small">
          <Button icon={<Close />} onClick={toggle} />
        </Box>
      </Box>
      {renderContent()}
    </AppLayer>
  );
};

ModalRenderer.propTypes = {
  toggle: PropTypes.func.isRequired,
  isSmall: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  renderContent: PropTypes.func.isRequired,
};

export default ModalRenderer;
