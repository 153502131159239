'use client';

import React from 'react';
import Script from 'next/script';

const HotjarSnippet = () => (
  <div>
    <Script id="hotjar-snippet">
      {`
        (function(h,o,t,j,a,r){
            h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
            h._hjSettings={hjid:5188297,hjsv:6};
            a=o.getElementsByTagName('head')[0];
            r=o.createElement('script');r.async=1;
            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
            a.appendChild(r);
        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
      `}
    </Script>
  </div>
);

const HotjarTracker = () => {
  return (
    <React.Suspense>
      <HotjarSnippet />
    </React.Suspense>
  );
};

export default HotjarTracker;