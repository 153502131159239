'use client';

import React from 'react';
import PropTypes from 'prop-types';
import { usePathname } from 'next/navigation';
import { track } from '@vercel/analytics';
import uniqid from 'uniqid';
import _ from 'lodash';

import {
  Box,
  Image,
  Button,
  Text,
  AccordionPanel,
} from 'grommet';
import { FormNext } from 'grommet-icons';
import { usePopper } from 'react-popper';

import {
  ArrowPopover, AppButton, AppAnchor, AppLayer,
} from '@/components/Control';

import { useClientMediaQuery } from '@/lib/clientMediaQuery';
import { optimizeImageSrc } from '@/helpers/util';

import { paths } from '@/components/configs';
import {
  StyledNavbar,
  StyledNavMenuWrapper,
  StyledLogin,
  StyledHomeAnchor,
  StyledMenuAnchor,
  StyledMenuLabel,
  StyledMenuDrop,
  StyledMenuDropDown,
  StyledDropDownTitle,
  StyledDropDownSubtitle,
  StyledAccordion,
  StyledMenuControlsContainer,
} from './StyledHeader';

import style from './Header.module.scss';

export const HeaderLoader = () => (
  <NavbarLayout><Box flex background="white" /></NavbarLayout>
);

const Navbar = ({
  navbarRef = React.createRef(),
  isSmall = false,
  darkBackground,
  ...rest
}) => (
  <StyledNavbar
    fill="horizontal"
    gridArea="header"
    direction="row"
    pad={{ horizontal: isSmall ? 'var(--vw5p725)' : 'var(--vw7p725)' }}
    height="5.375rem"
    background={darkBackground ? 'rgba(0,0,0,0)' : 'white'}
    gap="1.2rem"
    align="center"
    flex={false}
    ref={navbarRef}
    {...rest}
  />
);

Navbar.propTypes = {
  navbarRef: PropTypes.shape({ current: PropTypes.node }),
  darkBackground: PropTypes.bool.isRequired,
  className: PropTypes.string,
  isSmall: PropTypes.bool,
};

const MenuButton = ({ label, buttonColor, trackLocation, ...rest }) => (
  <StyledLogin {...rest}>
    <AppButton
      overrideHover
      level="dynamicLarge"
      color="white"
      bgColor={buttonColor}
      fontWeight={600}
      label={label}
      onClick={trackLocation && (() => track(trackLocation))}
    />
  </StyledLogin>
);

MenuButton.propTypes = {
  label: PropTypes.string.isRequired,
  buttonColor: PropTypes.string.isRequired,
  trackLocation: PropTypes.string,
};

const MobileMenuButton = ({
  label,
  path = null,
  href = null,
  onClick,
  buttonColor,
  icon,
}) => (
  <AppAnchor path={path} href={href}>
    <AppButton
      overrideHover
      level="mobileMenu"
      color="white"
      justify="start"
      bgColor={buttonColor}
      fontWeight={600}
      label={(
        <Box direction="row" width="100%" justify="between" pad={{ right: '20px' }}>
          <Text color="white" size="20px">
            {label}
          </Text>
          <Box align="center" justify="center" width="25px" height="25px">
            <FormNext size="25px" color="white" />
          </Box>
        </Box>
      )}
      onClick={() => onClick()}
      icon={icon}
    />
  </AppAnchor>
);

MobileMenuButton.propTypes = {
  label: PropTypes.string.isRequired,
  path: PropTypes.string,
  href: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  buttonColor: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
};

const MenuLabel = ({ label, ...rest }) => (
  <StyledMenuLabel>
    <Text color={rest.darkBackground ? 'white' : '#3C3C3C'}>{label}</Text>
  </StyledMenuLabel>
);

MenuLabel.propTypes = {
  label: PropTypes.string.isRequired,
};

const MenuAnchor = ({
  path = null,
  label = null,
  onClick = () => null,
  darkBackground = false,
  hoverColor = '',
  ...rest
}) => (
  <StyledMenuAnchor
    path={path}
    label={label}
    onClick={onClick}
    color={darkBackground ? 'white' : '#3C3C3C'}
    hoverColor={hoverColor}
    {...rest}
  />
);

MenuAnchor.propTypes = {
  path: PropTypes.string,
  label: PropTypes.node,
  onClick: PropTypes.func,
  darkBackground: PropTypes.bool,
  className: PropTypes.string,
  hoverColor: PropTypes.string,
};

const MenuDrop = ({
  menuAlign = 'start',
  anchorComp = null,
  children,
  ...rest
}) => {
  const [visible, setVisible] = React.useState(false);
  const [targetRef, setTargetRef] = React.useState(null);
  const [popperRef, setPopperRef] = React.useState(null);
  const [arrowRef, setArrowRef] = React.useState(null);
  const { styles, attributes } = usePopper(targetRef, popperRef, {
    placement: 'bottom-start',
    modifiers: [{ name: 'arrow', options: { element: arrowRef } }],
  });

  return (
    <StyledMenuDrop
      ref={setTargetRef}
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
      onTouchStart={() => setVisible(true)}
    >
      <MenuLabel {...rest}>
        {anchorComp}
      </MenuLabel>
      {visible && (
        <ArrowPopover.Container
          shadow="0 0 1.5625rem 0 rgba(0, 0, 0, 0.06)"
          ref={setPopperRef}
          align={menuAlign}
          background="white"
          round="0.2rem"
          offset="-0.5rem"
          arrowDepth="0.25rem"
          direction="column"
          style={styles.popper}
          {...attributes.popper}
        >
          <ArrowPopover.DirectedArrow ref={setArrowRef} style={styles.arrow} />
          {children(() => setVisible(false))}
        </ArrowPopover.Container>
      )}
    </StyledMenuDrop>
  );
};

MenuDrop.propTypes = {
  menuAlign: PropTypes.string,
  anchorComp: PropTypes.node,
  children: PropTypes.func.isRequired,
};

const Logo = ({
  darkBackground,
  colorLogo = false,
  isSmall = false,
  ...rest
}) => {
  const logoWidth = isSmall ? '11.65rem' : '10.65rem';
  const logoHeight = isSmall ? '3.25rem' : '2.25rem';

  let imageSrc = darkBackground ? 'logo-full-white.svg' : 'logo-full-color.svg';
  if (colorLogo) imageSrc = 'logo-full-color.svg';

  return (
    <StyledHomeAnchor path={paths.root} {...rest}>
      <Box flex={false} width={logoWidth} height={logoHeight}>
        <Image
          src={`/images/logo/${imageSrc}`}
          alt="Redflag AI Logo"
          fit="contain"
          width={logoWidth}
          height={logoHeight}
        />
      </Box>
    </StyledHomeAnchor>
  );
};

Logo.propTypes = {
  darkBackground: PropTypes.bool.isRequired,
  colorLogo: PropTypes.bool,
  isSmall: PropTypes.bool,
};

const buildDropDownItem = (title, caption, icon) => (
  <Box gap="0.15rem">
    <Box direction="row" gap="0.6rem">
      {icon
        && (
        <Box width="1.24rem" height="1.24rem">
          <Image
            src={icon('')}
            alt={`${title} icon`}
            fit="contain"
            width="1.24rem"
            height="1.24rem"
          />
        </Box>
        )}
      <StyledDropDownTitle>{title}</StyledDropDownTitle>
    </Box>
    {caption
      && (
      <StyledDropDownSubtitle size="0.7rem" color="#707070" margin={icon ? { left: '1.84rem' } : null}>
        {caption}
      </StyledDropDownSubtitle>
      )}
  </Box>
);

const NavbarLayout = ({
  children, darkBackground, ...rest
}) => (
  <Navbar darkBackground={darkBackground} {...rest}>
    <Box pad={{ right: '2.5rem' }}>
      <Logo darkBackground={darkBackground} />
    </Box>
    <StyledNavMenuWrapper
      direction="row"
      flex="grow"
      align="center"
      justify="end"
    >
      {children}
    </StyledNavMenuWrapper>
  </Navbar>
);

NavbarLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node.isRequired),
    PropTypes.node,
  ]).isRequired,
  darkBackground: PropTypes.bool.isRequired,
};

const NavbarSmallLayout = (props) => {
  const {
    children,
    secondaryMenuContent,
    showOverlay,
    toggle,
    darkBackground,
    ...rest
  } = props;
  const iconSrc = darkBackground ? '/images/menu-open-icon-light.svg' : '/images/menu-open-icon-dark.svg';
  const navHeight = 'calc(5.375rem + 1px)';

  return (
    <>
      <Navbar isSmall darkBackground={darkBackground} {...rest}>
        <Box width="100%" justify="center" align="start">
          <Logo isSmall darkBackground={darkBackground} />
        </Box>
        <StyledMenuControlsContainer>
          <Button
            icon={(
              <Box direction="row" gap="10px">
                <Text color={darkBackground ? 'white' : '#3C3C3C'} size="1.15rem" style={{ lineHeight: '1.47rem' }}>
                  Menu
                </Text>
                <Box flex={false} width="1.5rem" height="1.5rem">
                  <Image
                    src={iconSrc}
                    alt="Open navigation menu"
                    width="1.5rem"
                    height="1.5rem"
                    fit="contain"
                  />
                </Box>
              </Box>
            )}
            onClick={toggle}
          />
        </StyledMenuControlsContainer>
      </Navbar>
      {showOverlay
        && (
        <AppLayer
          full
          contentFill
          responsive={false}
          animate={false}
          round="0"
        >
          <Navbar
            isSmall
            height={navHeight}
            darkBackground={darkBackground}
            className={style.navbarNotFixed}
          >
            <Box
              fill
              direction="row"
              gap="1.2rem"
              align="center"
              justify="center"
              border={{ side: 'bottom', size: '1px', color: '#D0D0D0' }}
            >
              <Box width="100%" justify="center" align="center">
                <Logo colorLogo isSmall darkBackground={darkBackground} />
              </Box>
              <StyledMenuControlsContainer>
                <Button
                  icon={(
                    <Box direction="row" gap="10px">
                      <Text color={darkBackground ? 'white' : '#3C3C3C'} size="1.15rem" style={{ lineHeight: '1.47rem' }}>
                        Close
                      </Text>
                      <Box flex={false} height="1.5rem" width="1.5rem" pad="0.15rem">
                        <Image
                          src="/images/menu-close-icon.svg"
                          alt="Close navigation menu"
                          width="1.5rem"
                          height="1.5rem"
                          fit="contain"
                        />
                      </Box>
                    </Box>
                  )}
                  onClick={toggle}
                />
              </StyledMenuControlsContainer>
            </Box>
          </Navbar>
          <Box fill>
            <Box flex justify="between" gap="1.2rem" pad={{ horizontal: '1.2rem' }}>
              <Box fill>
                {children}
              </Box>
            </Box>
            <Box fill="horizontal">
              {secondaryMenuContent}
            </Box>
          </Box>
        </AppLayer>
        )}
    </>
  );
};

NavbarSmallLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node.isRequired),
    PropTypes.node,
  ]).isRequired,
  secondaryMenuContent: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node.isRequired),
    PropTypes.node,
  ]).isRequired,
  showOverlay: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  darkBackground: PropTypes.bool.isRequired,
};

let prevClassName;

const Header = ({
  config, solutions = null, industries = null,
}) => {
  const [showOverlay, setShowOverlay] = React.useState(false);
  const [isScroll, setIsScroll] = React.useState(false);
  const navbarRef = React.useRef();
  const pathname = usePathname();
  const small = useClientMediaQuery('(max-width: 768px)');
  let darkHeader = false;

  if (_.includes(config?.navColorMap?.darkHeader, pathname)) {
    darkHeader = true;
  }

  const useDarkBg = isScroll ? false : darkHeader;
  const {
    buttonColor,
    headerHoverColor,
    loginIconLight,
    loginIconDark,
  } = config;

  const checkBackgroundColor = () => {
    if (showOverlay) {
      return;
    }

    const navbar = navbarRef.current;

    const scrollThreshold = Math.ceil(navbar.getBoundingClientRect().bottom + 1);

    if (window.scrollY < scrollThreshold) {
      setIsScroll(false);

      if (prevClassName) {
        navbar.className = prevClassName;
        prevClassName = null;
      }
    } else {
      setIsScroll(true);

      if (!prevClassName) {
        prevClassName = navbar.className;
      }

      navbar.className = `${prevClassName} ${style.navbarScroll}`;
    }
  };

  React.useEffect(() => {
    window.addEventListener('scroll', () => checkBackgroundColor());

    return () => {
      window.removeEventListener('scroll', () => checkBackgroundColor());
    };
  });

  if (small) {
    return (
      <NavbarSmallLayout
        darkBackground={useDarkBg}
        toggle={() => setShowOverlay(!showOverlay)}
        showOverlay={showOverlay}
        navbarRef={navbarRef}
        config={config}
        secondaryMenuContent={(
          <>
            <MobileMenuButton
              label="Book a Demo"
              path={paths.contact}
              onClick={() => {
                track('Mobile Nav - Book a Demo click');
                setShowOverlay(false)
              }}
              buttonColor={config.buttonColor}
              icon={(
                <Box flex={false} width="20px" height="20px" margin={{ right: '12px' }}>
                  <Image
                    src={optimizeImageSrc(config.bookDemoIconLight)}
                    alt="Book a Demo"
                    fit="contain"
                    width="20px"
                    height="20px"
                  />
                </Box>
              )}
            />
            <MobileMenuButton
              label="Login"
              path="https://app.redflagai.co"
              onClick={() => setShowOverlay(false)}
              buttonColor={config.buttonColor}
              icon={(
                <Box flex={false} width="20px" height="20px" margin={{ right: '12px' }}>
                  <Image
                    src={optimizeImageSrc(config.loginIconLight)}
                    alt="Redflag AI Login"
                    fit="contain"
                    width="20px"
                    height="20px"
                  />
                </Box>
              )}
            />
          </>
        )}
      >
        <StyledAccordion>
          <AccordionPanel label={<MenuLabel label="Solutions" />}>
            <Box align="stretch" pad={{ left: '2rem', right: '1rem' }}>
              {solutions && Object.entries(solutions).map(([k, f]) => (
                <MenuAnchor
                  key={k}
                  path={paths.solutions.replace(':uid', k)}
                  label={buildDropDownItem(f.title, null, f.icon)}
                  onClick={() => {
                    track(`Mobile Nav - Solutions ${f.title} click`);
                    setShowOverlay(false)
                  }}
                  className={style.accordionItem}
                />
              ))}
            </Box>
          </AccordionPanel>
          <AccordionPanel label={<MenuLabel label="Industries" />}>
            <Box align="stretch" pad={{ left: '2rem', right: '1rem' }}>
              {industries && Object.entries(industries).map(([k, f]) => (
                <MenuAnchor
                  key={k}
                  path={paths.industries.replace(':uid', k)}
                  label={buildDropDownItem(f.title, null, f.icon)}
                  onClick={() => {
                    track(`Mobile Nav - Industries ${f.title} click`);
                    setShowOverlay(false)
                  }}
                  className={style.accordionItem}
                />
              ))}
            </Box>
          </AccordionPanel>
          <AccordionPanel label={<MenuLabel label="Resources" />}>
            <Box align="stretch" pad={{ left: '2rem', right: '1rem' }}>
              <MenuAnchor
                key="articles"
                path={paths.blog}
                label={buildDropDownItem('Blogs and News')}
                onClick={() => {
                  track(`Mobile Nav - Resources Blog and News click`);
                  setShowOverlay(false)
                }}
                className={style.accordionItem}
              />
              <MenuAnchor
                key="case-studies"
                path={paths.caseStudies}
                label={buildDropDownItem('Case Studies')}
                onClick={() => {
                  track(`Mobile Nav - Resources Case Studies click`);
                  setShowOverlay(false)
                }}
                className={style.accordionItem}
              />
              <MenuAnchor
                key="piracy-reports"
                path={paths.piracyReport.replace(':uid', 'q3-piracy-market-trends-report')}
                label={buildDropDownItem('Quarterly Piracy Report')}
                onClick={() => {
                  track(`Mobile Nav - Resources Quarterly Piracy Report click`);
                  setShowOverlay(false)
                }}
                className={style.accordionItem}
              />
            </Box>
          </AccordionPanel>
        </StyledAccordion>
        <MenuAnchor path={paths.about} label="About Us" onClick={() => setShowOverlay(false)} />
      </NavbarSmallLayout>
    );
  }

  return (
    <NavbarLayout darkBackground={useDarkBg} navbarRef={navbarRef}>
      <Box flex direction="row" justify="start">
        <MenuDrop darkBackground={useDarkBg} menuAlign="end" label="Solutions">
          {((closeHover) => solutions && Object.entries(solutions).map(([k, f]) => (
            <StyledMenuDropDown
              key={k}
              path={paths.solutions.replace(':uid', k)}
              label={buildDropDownItem(f.title, f.caption, f.icon)}
              onClick={() => {
                track(`Desktop Nav - Solutions ${f.title} click`);
                closeHover();
              }}
              hoverColor={headerHoverColor}
            />
          )))}
        </MenuDrop>
        <MenuDrop darkBackground={useDarkBg} menuAlign="end" label="Industries">
          {((closeHover) => industries && Object.entries(industries).map(([k, f]) => (
            <StyledMenuDropDown
              key={k}
              path={paths.industries.replace(':uid', k)}
              label={buildDropDownItem(f.title, f.caption, f.icon)}
              onClick={() => {
                track(`Desktop Nav - Industries ${f.title} click`);
                closeHover();
              }}
              hoverColor={headerHoverColor}
            />
          )))}
        </MenuDrop>
        <MenuDrop darkBackground={useDarkBg} menuAlign="end" label="Resources">
          {((closeHover) => (
            <>
              <StyledMenuDropDown
                key="articles"
                path={paths.blog}
                label={buildDropDownItem('Blogs and News')}
                onClick={() => {
                  track(`Desktop Nav - Resources Blogs and News click`);
                  closeHover();
                }}
                hoverColor={headerHoverColor}
              />
              <StyledMenuDropDown
                key="case-studies"
                path={paths.caseStudies}
                label={buildDropDownItem('Case Studies')}
                onClick={() => {
                  track(`Desktop Nav - Resources Case Studies click`);
                  closeHover();
                }}
                hoverColor={headerHoverColor}
              />
              <StyledMenuDropDown
                key="piracy-reports"
                path={paths.piracyReport.replace(':uid', 'q3-piracy-market-trends-report')}
                label={buildDropDownItem('Quarterly Piracy Report')}
                onClick={() => {
                  track(`Desktop Nav - Resources Quarterly Piracy Report click`);
                  closeHover();
                }}
                hoverColor={headerHoverColor}
              />
            </>
          ))}
        </MenuDrop>
        <MenuAnchor darkBackground={useDarkBg} path={paths.about} label="About Us" hoverColor={headerHoverColor} />
      </Box>
      <Box direction="row" gap="1rem" justify="end" align="center">
        <StyledHomeAnchor
          href="https://app.redflagai.co"
          label="Sign In"
          color={useDarkBg ? 'white' : '#3C3C3C'}
          weight={600}
          size="1.15rem"
          icon={(
            <Box flex={false} width="1.15rem" height="1.15rem" margin={{ bottom: '0.1rem' }}>
              <Image
                src={useDarkBg ? optimizeImageSrc(loginIconLight) : optimizeImageSrc(loginIconDark)}
                alt="Redflag Login"
                fit="contain"
                width="1.15rem"
                height="1.15rem"
              />
            </Box>
          )}
        />
        <MenuButton key={uniqid()} label="Book a Demo" buttonColor={buttonColor} path={paths.contact} trackLocation="Desktop Nav - Book a Demo click" />
      </Box>
    </NavbarLayout>
  );
};

Header.propTypes = {
  config: PropTypes.shape({
    buttonColor: PropTypes.string.isRequired,
    headerHoverColor: PropTypes.string.isRequired,
    loginIconLight: PropTypes.string.isRequired,
    loginIconDark: PropTypes.string.isRequired,
    bookDemoIconLight: PropTypes.string.isRequired,
    caseStudiesImage: PropTypes.string.isRequired,
  }).isRequired,
  industries: PropTypes.objectOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    image: PropTypes.string,
  })),
  solutions: PropTypes.objectOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    image: PropTypes.string,
  })),
};

export default Header;
