import React from 'react';
import styled from 'styled-components';

import {
  Box, Text, Button,
} from 'grommet';
import { AppAnchor } from '@/components/Control';

const StyledFooterAnchor = styled((props) => <AppAnchor {...props} />)`
  && {
    font-weight: 400;
    font-size: 16px;
    backface-visibility: hidden;
    transform: translateZ(0);
    -webkit-transition: all 0.2s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
    transition: all 0.2s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
    transform-box: fill-box;

    * {
      backface-visibility: hidden;
      transform: translateZ(0);
      -webkit-transition: all 0.2s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
      transition: all 0.2s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
      transform-box: fill-box;
      transform-origin: 50% 50%;
    }

    span {
      font-weight: 400;
      font-size: 16px;
      display: flex;
      align-items: center;
    }

    svg {
      width: 16px;
      height: 16px;
    }

    &:hover {
      color: ${({ color }) => ((color === '#7F7F7F') ? '#788af7' : 'white')};
      opacity: 1;

      ${({ withChildren }) => (withChildren ? `
        span {
          transform: scale(1.0125);
        }

        svg {
          width: 16.5px;
          height: 16.5px;
        }
      ` : `
        transform: scale(1.0125);
      `)}
    }

    @media (max-width: 1440px) {
      font-size: 12px;

      span {
        font-size: 12px;
      }

      svg {
        width: 12px;
        height: 12px;
      }
    }

    @media (max-width: 768px) {
      font-size: 14px;

      span {
        font-size: 14px;
      }

      svg {
        width: 14px;
        height: 14px;
      }
    }
  }
`;

const StyledFooterAnchorWrapper = styled(Box)`
  &:hover {
    ${StyledFooterAnchor}:not(:hover) {
      opacity: ${({ overrideHover }) => (overrideHover ? '0.6' : '1')};
    }
  }
`;

const StyledFooterSection = styled(Box)`
  margin-right: 4.5rem;

  @media (max-width: 768px) {
    width: ${({ logoContainer }) => (logoContainer ? '100%' : '12.5rem')};
    margin-right: ${({ logoContainer }) => (logoContainer ? '0' : '1.5rem')};
  }
`;

const StyledFooterHeading = styled(Text)`
  font-weight: 600;
  font-size: 1.1rem;
  word-break: keep-all;

  @media (max-width: 768px) {
    font-size: 1.4rem;
  }
`;

const StyledModalButton = styled(Button)`
  -webkit-transition: all 0.2s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
  transition: all 0.2s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;

  &:hover {
    color: #004FFE;
  }
`;

const StyledMailtoAnchor = styled(AppAnchor)`
  -webkit-transition: all 0.2s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
  transition: all 0.2s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;

  &:hover {
    color: #004FFE;
  }
`;

export {
  StyledFooterAnchor,
  StyledFooterAnchorWrapper,
  StyledFooterSection,
  StyledFooterHeading,
  StyledModalButton,
  StyledMailtoAnchor,
};
