import { css } from 'styled-components';

const dark = '#2c2e38';
const primary = '#548db7';
const fontSizing = (factor) => ({
  size: `${1 + (factor * (1 / 3))}rem`,
  height: 'normal',
  maxWidth: 'none',
});

const extend = {
  button: (props) => {
    let style = '';
    if (props.primary) {
      style = `${style}border-radius: 0;`;
      if (props.dark) {
        style = `${style}color: ${dark};`;
      } else {
        style = `
          ${style}color: white; border-color: ${props.colorValue || primary};
          :hover:not(:disabled) {
            box-shadow: 0px 0px 0px 2px ${props.colorValue || primary};
          }`;
      }
    } else {
      style = `color: ${props.colorValue};`;
    }

    if (props.uppercase) {
      style = `${style}text-transform: uppercase`;
    }

    return style;
  },
};

const themeConfig = {
  global: {
    font: {
      family: 'var(--app-font-primary)',
      ...fontSizing(0),
    },
    colors: {
      brand: primary,
      text: {
        dark: 'white',
        light: dark,
      },
      control: {
        dark: 'white',
        light: dark,
      },
      focus: primary,
    },
    focus: {
      border: {
        color: 'none',
      },
    },
    control: {
      border: {
        radius: 0,
      },
    },
    input: {
      weight: 'normal',
    },
    textarea: {
      weight: 'normal',
    },
    breakpoints: {
      xsmall: {
        value: 500,
      },
      medium: {
        value: 1536,
      },
      mediumlarge: {
        value: 1920,
      },
    },
    drop: {
      border: {
        radius: '4px',
      },
      extend: css`
        max-height: 100% !important;
        z-index: 1;
        box-shadow: 0 0 1.5625rem 0 rgba(0, 0, 0, 0.06);
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;

        button > div > svg  {
          margin-right: 0.6rem;
        }

        transform-origin: unset;
        animation-delay: unset;
        animation: none;
        opacity: 1;
      `,
    },
  },
  text: {
    xxsmall: { ...fontSizing(-0.5) },
    xsmall: { ...fontSizing(-0.25) },
    small: { ...fontSizing(0) },
    medium: { ...fontSizing(0.25) },
    large: { ...fontSizing(0.5) },
    xlarge: { ...fontSizing(0.75) },
    xxlarge: { ...fontSizing(1) },
    extend: css`
      ${({ weight }) => !weight && 'font-weight: normal;'}
      ${({ uppercase }) => uppercase && 'letter-spacing: 0.2em;'}
      ${({ uppercase }) => uppercase && 'text-transform: uppercase;'}
    `,
  },
  paragraph: {
    xxsmall: { ...fontSizing(-0.5) },
    xsmall: { ...fontSizing(-0.25) },
    small: { ...fontSizing(0) },
    medium: { ...fontSizing(0.25) },
    large: { ...fontSizing(0.5) },
    xlarge: { ...fontSizing(0.75) },
    xxlarge: { ...fontSizing(1) },
    extend: css`${() => 'font-weight: normal;'}`,
  },
  heading: {
    level: {
      1: {
        small: { ...fontSizing(4) },
        medium: { ...fontSizing(8) },
        large: { ...fontSizing(16) },
        xlarge: { ...fontSizing(24) },
      },
      2: {
        small: { ...fontSizing(2.25) },
        medium: { ...fontSizing(4) },
        large: { ...fontSizing(6) },
        xlarge: { ...fontSizing(10) },
      },
      3: {
        small: { ...fontSizing(1.5) },
        medium: { ...fontSizing(2) },
        large: { ...fontSizing(4) },
        xlarge: { ...fontSizing(6) },
      },
      4: {
        small: { ...fontSizing(0) },
        medium: { ...fontSizing(0) },
        large: { ...fontSizing(0.5) },
        xlarge: { ...fontSizing(1) },
      },
      5: {
        small: { ...fontSizing(-0.5) },
        medium: { ...fontSizing(-0.5) },
        large: { ...fontSizing(-0.25) },
        xlarge: { ...fontSizing(-0.25) },
      },
      6: {
        small: { ...fontSizing(-1) },
        medium: { ...fontSizing(-1) },
        large: { ...fontSizing(-1) },
        xlarge: { ...fontSizing(-1) },
      },
    },
    responsiveBreakpoint: null,
    extend: css`
      font-weight: 700;
      ${({ thin }) => thin && 'font-weight: normal;'}
      ${({ uppercase }) => uppercase && 'letter-spacing: 0.2em; text-transform: uppercase;'}
    `,
  },
  textInput: {
    extend: css`${() => 'padding: 0.3rem 0.6rem'};`,
  },
  textArea: {
    extend: css`${() => 'padding: 0.3rem 0.6rem'};`,
  },
  anchor: {
    color: {
      dark: 'white',
      light: dark,
    },
    hover: {
      textDecoration: 'none',
    },
    fontWeight: 'normal',
  },
  button: {
    primary: {
      color: 'brand',
    },
    border: {
      radius: 0,
    },
    padding: {
      horizontal: '1.2rem',
      vertical: '0.6rem',
    },
    extend: css`${extend.button}`,
  },
  radioButton: {
    border: { width: '1px' },
    color: 'brand',
    check: { background: { color: 'white' }, color: 'brand' },
    hover: { border: { color: 'brand' } },
    extend: css`flex-shrink: 0;`,
  },
  table: {
    header: {
      border: {
        color: 'light-6',
        side: 'bottom',
      },
    },
  },
  tab: {
    color: 'light-6',
    hover: { color: 'light-6' },
    border: {
      size: 'medium',
      color: 'transparent',
      hover: {
        color: 'light-6',
      },
    },
  },
  carousel: {
    icons: {
      color: 'dark-3',
    },
  },
  icon: {
    color: dark,
    extend: css`${(props) => props.fill
      && `*[stroke*="#"],
      *[STROKE*="#"] {
        fill: ${props.fill};
      }`
    };`,
  },
  select: {
    icons: { margin: 'none' },
  },
};

export default themeConfig;
