'use client';

import React from 'react';
import { usePathname, useSearchParams } from 'next/navigation';

const HubspotTrackerSnippet = () => {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const searchParamsDict = Object.fromEntries(searchParams ? searchParams.entries() : []);

  const emailAddress = searchParamsDict.email ? searchParamsDict.email : null;

  const firstLoad = React.useRef(true);

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      var _hsq = window._hsq = window._hsq || [];

      if (firstLoad.current === true) {
        _hsq.push(['setPath', pathname]);
        _hsq.push(['trackPageView']);

        if (emailAddress) {
          _hsq.push(['identify', { email: emailAddress }]);
        }

        _hsq.push(['setPath', pathname]);
        _hsq.push(['trackPageView']);

        firstLoad.current = false;
      } else {
        _hsq.push(['setPath', pathname]);
        _hsq.push(['trackPageView']);
      }
    }
  }, [pathname, searchParamsDict, emailAddress]);

  return null;
};

const HubspotTracker = () => {
  return (
    <React.Suspense>
      <HubspotTrackerSnippet />
    </React.Suspense>
  );
};

export default HubspotTracker;