import React from 'react';
import styled from 'styled-components';

import { Box, Text, Accordion } from 'grommet';

import AppAnchor, { StyledAnchor } from '@/components/Control/AppAnchor';

import style from './Header.module.scss';

const StyledMenuAnchor = styled((props) => <AppAnchor {...props} />)`
  && {
    font-size: 1.15rem;
    font-weight: 600;
    padding: 1.5rem 1.25rem;
    line-height: 1.25;
    letter-spacing: normal;

    @media(min-width: 48.0625em) and (max-width: 56.25em) {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }

    @media (max-width: 48em) {
      padding: 1.25rem 1rem;
      font-size: 20px;
    }
  }
`;

const StyledMenuLabel = styled(Box)`
  padding: 1.5rem 1.25rem;

  span {
    font-size: 1.15rem;
    font-weight: 600;
    line-height: 1.25;
    letter-spacing: normal;
  }

  @media(min-width: 48.0625em) and (max-width: 56.25em) {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  @media (max-width: 48em) {
    padding: 1.25rem 1rem;
    span { font-size: 20px; };
  }
`;

const StyledHomeAnchor = styled((props) => <AppAnchor {...props} />)`
  && { padding: 0; }
`;

const StyledDropDownSubtitle = styled(Text)`
  font-weight: 600;
  line-height: 1.29;
  letter-spacing: normal;
  text-transform: none;
`;

const StyledDropDownTitle = styled.span`
  text-transform: none;
`;

const StyledMenuDrop = styled(Box)`
  &:last-child > .${style.profileAnchor} {
    padding-right: 0;
  }
`;

const StyledMenuDropDownAnchor = styled(StyledMenuAnchor)`
  && {
    width: 100%;
    padding: 0.5rem 1rem 0.5rem 1rem;
    background: white;

    &:first-of-type {
      margin-top: 0.5rem;
      border-radius: 0.2rem 0.2rem 0 0;
    }

    &:last-of-type {
      margin-bottom: 0.5rem;
      border-radius: 0 0 0.2rem 0.2rem;
    }

    &:hover, &:hover * {
      color: ${({ hoverColor }) => hoverColor};
    }
  }
`;

const StyledMenuDropDown = styled(StyledMenuAnchor)`
  && {
    width: 100%;
    padding: 0.5rem 1rem 0.5rem 1rem;
    background: white;

    &:first-of-type {
      margin-top: 0.5rem;
      border-radius: 0.2rem 0.2rem 0 0;
    }

    &:last-of-type {
      margin-bottom: 0.5rem;
      border-radius: 0 0 0.2rem 0.2rem;
    }

    &:hover, &:hover * {
      color: ${({ hoverColor }) => hoverColor};
    }

    &${StyledAnchor} {
      text-decoration: none;

      ${StyledDropDownTitle} { text-decoration: underline; }
    }
  }
`;

const StyledAccordion = styled(Accordion)`
  padding: 0;

  & .${style.accordionItem}${StyledAnchor} {
    text-decoration: none;

    ${StyledDropDownTitle} { text-decoration: underline; }
  }

  div { border: none; }

  svg {
    width: 28px;
    height: 28px;
    fill: #636363;
    stroke: #636363;
  }

  button {
    border-radius: 5px;
  }

  button[aria-expanded="true"] {
    background: #F4F4F4;
  }
`;

const StyledProfile = styled(Box)`
  cursor: pointer;

  span, button, a {
    font-size: 0.8rem;
    letter-spacing: 0.125rem;
  }

  @media (max-width: 48em) {
    padding: 1rem 0;
  }
`;

const StyledLogin = styled((props) => <AppAnchor {...props} />)`
  && {
    margin: 0.75rem;
    margin-right: 0;

    &:hover { color: #3C417F; }

    @media(min-width: 48.0625em) and (max-width: 56.25em) {
      margin-left: 0.6vw;
    }

    @media (max-width: 48em) {
      margin: 1rem 0;

      > button { width: 100%; }
    }
  }
`;

const StyledNavbar = styled(Box)`
  &:not(.${style.navbarNotFixed}) {
    position: fixed;
    z-index: 3;
    top: 0;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: 'space-between';
  }
`;


const StyledNavMenuWrapper = styled(Box)`
  &:hover {
    > ${StyledMenuAnchor}:not(:hover),
    > ${StyledMenuDrop}:not(:hover) > ${StyledMenuAnchor},
    > ${StyledLogin}:not(:hover) {
      opacity: 0.6;
    }
  }
`;

const StyledMenuControlsContainer = styled(Box)`
  position: absolute;
  top: 1rem;
  right: 1rem;
`;

export {
  StyledNavbar,
  StyledNavMenuWrapper,
  StyledLogin,
  StyledProfile,
  StyledHomeAnchor,
  StyledMenuAnchor,
  StyledMenuLabel,
  StyledMenuDrop,
  StyledMenuDropDown,
  StyledMenuDropDownAnchor,
  StyledDropDownTitle,
  StyledDropDownSubtitle,
  StyledAccordion,
  StyledMenuControlsContainer,
};
