'use client';

import React from 'react';
import PropTypes from 'prop-types';

import {
  Box, Text, Image,
} from 'grommet';
import { Chat, Linkedin } from 'grommet-icons';

import { PrivacyPolicyModal, TermsOfUseModal } from '@/components/Modal';

import { useClientMediaQuery } from '@/lib/clientMediaQuery';
import { optimizeImageSrc } from '@/helpers/util';
import { paths } from '@/components/configs';
import {
  StyledFooterHeading,
  StyledFooterAnchor,
  StyledFooterAnchorWrapper,
  StyledFooterSection,
  StyledModalButton,
  StyledMailtoAnchor,
} from './StyledFooter';

const FooterSection = ({
  isSmall, children, logoContainer = false, ...rest
}) => {
  let topMargin = isSmall ? '3rem' : '2.4rem';

  if (logoContainer) {
    topMargin = isSmall ? '3rem' : '2rem';
  }

  return (
    <StyledFooterSection
      align="start"
      margin={{ top: topMargin }}
      logoContainer={logoContainer}
      {...rest}
    >
      {children}
    </StyledFooterSection>
  );
};

FooterSection.propTypes = {
  isSmall: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  logoContainer: PropTypes.bool,
};

const FooterHeading = ({ title, textColor }) => (
  <StyledFooterHeading margin="none" color={textColor}>
    {title}
  </StyledFooterHeading>
);

FooterHeading.propTypes = {
  title: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
};

const FooterContainer = ({
  isSmall, bgColor, children, ...rest
}) => (
  <Box
    fill="horizontal"
    gridArea="footer"
    align="start"
    flex={false}
    pad={isSmall ? { horizontal: 'var(--vw5p725)', vertical: '1.25rem' } : { horizontal: 'var(--vw7p725)', vertical: '2rem' }}
    background={bgColor}
    {...rest}
  >
    {children}
  </Box>
);

FooterContainer.propTypes = {
  isSmall: PropTypes.bool.isRequired,
  bgColor: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

const FooterAnchor = ({ isSmall, children = null, ...rest }) => {
  if (children) {
    return (
      <StyledFooterAnchor withChildren margin={{ top: isSmall ? '1rem' : '0.75rem' }} {...rest}>
        {children}
      </StyledFooterAnchor>
    );
  }

  return (
    <StyledFooterAnchor margin={{ top: isSmall ? '1rem' : '0.75rem' }} {...rest} />
  );
};

FooterAnchor.propTypes = {
  isSmall: PropTypes.bool.isRequired,
  children: PropTypes.node,
};

const Footer = ({
  config: {
    bgColor,
    upperTextColor,
    lowerBgColor,
    lowerTextColor,
    secondaryLowerTextColor,
    trustedReporterIcon,
  },
  solutions = null,
  industries = null,
}) => {
  const [showPrivacyModal, setShowPrivacyModal] = React.useState(false);
  const [showTermsModal, setShowTermsModal] = React.useState(false);
  const [currentYear, setCurrentYear] = React.useState(null);
  const isSmall = useClientMediaQuery('(max-width: 768px)');

  React.useEffect(() => {
    const currentYear = new Date().getFullYear();

    setCurrentYear(currentYear);
  }, []);

  const togglePrivacy = () => {
    setShowPrivacyModal(!showPrivacyModal);
  };

  const toggleTerms = () => {
    setShowTermsModal(!showTermsModal);
  };

  const textSize = isSmall ? '0.9rem' : '0.75rem';
  const gapSize = isSmall ? '0.5rem' : '0.3rem';

  return (
    <Box flex direction="column">
      <FooterContainer
        isSmall={isSmall}
        bgColor={bgColor}
      >
        <Box pad={{ bottom: '2.4rem' }} width="100%">
          <StyledFooterAnchorWrapper overrideHover={false} direction={isSmall ? 'column' : 'row'} width="100%">
            <FooterSection logoContainer isSmall={isSmall} direction={isSmall ? 'row' : 'column'} gap="2rem" justify={isSmall ? 'between' : 'start'} align={isSmall ? 'center' : 'start'}>
              <Box justify="start" align="start" width={isSmall ? '13rem' : '10rem'} height={isSmall ? 'fit-content' : '2rem'}>
                <Image
                  fit="contain"
                  src="/images/logo/logo-full-white.svg"
                  alt="Redflag AI logo"
                  width={isSmall ? '100%' : '10rem'}
                  height={isSmall ? 'auto' : '2rem'}
                />
              </Box>
              {trustedReporterIcon && (
                <Box
                  justify="start"
                  align="start"
                  width={isSmall ? '8.5rem' : '9rem'}
                  height={isSmall ? 'fit-content' : '2.65rem'}
                  margin={isSmall ? { bottom: '-1rem' } : { left: '-1.25rem'}}
                >
                  <Image
                    fit="contain"
                    src={optimizeImageSrc(trustedReporterIcon)}
                    alt="Cloudflare Trusted Reporter"
                    width={isSmall ? '100%' : '9rem'}
                    height={isSmall ? 'auto' : '2.65rem'}
                  />
                </Box>
              )}
            </FooterSection>
            <FooterSection isSmall={isSmall}>
              <FooterHeading title="Redflag AI" textColor={upperTextColor} />
              <FooterAnchor path={paths.contact} isSmall={isSmall} label="Book a Demo" color={upperTextColor} />
              <FooterAnchor path={paths.about} isSmall={isSmall} label="About Us" color={upperTextColor} />
              <FooterAnchor isSmall={isSmall} href="https://app.redflagai.co" label="Login" color={upperTextColor} />
              <FooterAnchor href="https://redflag.bamboohr.com/careers" label="Careers" isSmall={isSmall} color={upperTextColor} />
              <FooterAnchor href="https://www.linkedin.com/company/redflag-ai/" isSmall={isSmall} color={upperTextColor}>
                <Text>
                  Social:
                  &nbsp;
                  <Linkedin color="white" />
                </Text>
              </FooterAnchor>
            </FooterSection>
            <FooterSection isSmall={isSmall}>
              <FooterHeading title="Solutions" textColor={upperTextColor} />
              {solutions && Object.entries(solutions).map(([uid, solution]) => (
                <FooterAnchor
                  key={uid}
                  isSmall={isSmall}
                  path={paths.solutions.replace(':uid', uid)}
                  label={solution.title}
                  color={upperTextColor}
                />
              ))}
            </FooterSection>
            <FooterSection isSmall={isSmall}>
              <FooterHeading title="Industries" textColor={upperTextColor} />
              {industries && Object.entries(industries).map(([uid, industry]) => (
                <FooterAnchor
                  key={uid}
                  isSmall={isSmall}
                  path={paths.industries.replace(':uid', uid)}
                  label={industry.title}
                  color={upperTextColor}
                />
              ))}
            </FooterSection>
            <FooterSection isSmall={isSmall}>
              <FooterHeading title="Resources" textColor={upperTextColor} />
              <FooterAnchor path={paths.blog} isSmall={isSmall} label="Blogs and News" color={upperTextColor} />
              <FooterAnchor
                path={paths.caseStudies}
                isSmall={isSmall}
                label="Case Studies"
                color={upperTextColor}
              />
              <FooterAnchor
                path={paths.piracyReport.replace(':uid', 'q3-piracy-market-trends-report')}
                isSmall={isSmall}
                label="Quarterly Piracy Report"
                color={upperTextColor}
              />
            </FooterSection>
          </StyledFooterAnchorWrapper>
        </Box>
      </FooterContainer>
      <Box
        fill="horizontal"
        align="center"
        justify="center"
        flex={false}
        pad={{ vertical: '0.75rem' }}
        background={lowerBgColor}
      >
        {showPrivacyModal
          && (
          <PrivacyPolicyModal
            toggle={() => togglePrivacy()}
            isSmall={isSmall}
            currentYear={currentYear}
          />
          )}
        {showTermsModal
          && (
          <TermsOfUseModal
            toggle={() => toggleTerms()}
            isSmall={isSmall}
            currentYear={currentYear}
          />
          )}
        <Box direction="row-responsive" gap={gapSize} justify="center" align="center">
          <Box direction="row" gap={gapSize} justify="center" align="center">
            <Box justify="center" align="center" width={isSmall ? '1rem' : '0.85rem'} height={isSmall ? '1rem' : '0.85rem'}>
              <Image
                fit="contain"
                src="/images/logo/logo-image-only-color.svg"
                alt="Redflag AI logo"
                width={isSmall ? '1rem' : '0.85rem'}
                height={isSmall ? '1rem' : '0.85rem'}
              />
            </Box>
            <Box>
              <Text weight={600} margin="none" size={textSize} color={lowerTextColor}>
                ©
                {' '}
                {currentYear}
                {' '}
                Redflag Artificial Intelligence Inc. All rights reserved.
              </Text>
            </Box>
          </Box>
          <Box direction="row" gap={gapSize} justify="center" align="center" pad={{ top: isSmall ? '0.3rem' : '0rem' }}>
            {!isSmall && (<Text size={textSize} color={secondaryLowerTextColor}>|</Text>)}
            <StyledModalButton
              plain
              onClick={() => togglePrivacy()}
              color={secondaryLowerTextColor}
            >
              <Box flex justify="center" align="center">
                <Text size={textSize}>Privacy Policy</Text>
              </Box>
            </StyledModalButton>
            <Text size={textSize} color={secondaryLowerTextColor}>|</Text>
            <StyledModalButton plain onClick={() => toggleTerms()} color={secondaryLowerTextColor}>
              <Box flex justify="center" align="center">
                <Text size={textSize}>Terms of Use</Text>
              </Box>
            </StyledModalButton>
            <Text size={textSize} color={secondaryLowerTextColor}>|</Text>
            <Box direction="row" gap={gapSize} justify="center" align="center">
              <Box>
                <Chat size="0.7rem" color={secondaryLowerTextColor} />
              </Box>
              <StyledMailtoAnchor href="mailto:contact@redflagai.co" target="_blank" color={secondaryLowerTextColor}>
                <Box flex justify="center" align="center">
                  <Text size={textSize}>Send Feedback</Text>
                </Box>
              </StyledMailtoAnchor>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

Footer.propTypes = {
  config: PropTypes.shape({
    bgColor: PropTypes.string.isRequired,
    upperTextColor: PropTypes.string.isRequired,
    lowerBgColor: PropTypes.string.isRequired,
    lowerTextColor: PropTypes.string.isRequired,
    secondaryLowerTextColor: PropTypes.string.isRequired,
    trustedReporterIcon: PropTypes.string,
  }).isRequired,
  industries: PropTypes.objectOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    image: PropTypes.string,
  })),
  solutions: PropTypes.objectOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    image: PropTypes.string,
  })),
};

export default Footer;
