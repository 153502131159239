'use client';

import React from 'react';
import { Grommet } from 'grommet';

import StyledComponentsRegistry from '@/lib/registry';
import '@/styles/scss/index.module.scss';
import theme from './theme';

export default function Providers({ children }) {
  return (
    <StyledComponentsRegistry>
      <Grommet theme={theme}>
        {children}
      </Grommet>
    </StyledComponentsRegistry>
  );
}
